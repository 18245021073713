

window.showMenu = function() {
  var menu = document.getElementById("js-menu");
  menu.classList.toggle("active");
}


window.hideMenu = function() {
  var menu = document.getElementById("js-menu");
  menu.classList.remove("active");
}
